<template>
  <div>
    <v-tabs v-model="tab" 
            background-color="grey"
            center-active
            dark>
      <v-tab key="quotes">
        <v-icon class="mr-3">mdi-bookmark</v-icon>
        Quotes 
        <v-chip class="ml-3">
          {{ issue.quotes_extended_count }}
        </v-chip>
      </v-tab>
      <v-tab key="notes">
        <v-icon class="mr-3">mdi-note-multiple</v-icon>
        Notes
        <v-chip class="ml-3">
          {{ issue.notes_extended_count }}
        </v-chip>
      </v-tab>
      <v-tab key="topics">
        <v-icon class="mr-3">mdi-brain</v-icon>
        Topics
        <v-chip class="ml-3">
          {{ issue.topics_count }}
        </v-chip>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" 
                  class="grey lighten-3 pb-15"
                  style="overflow:hidden;">
      <v-tab-item key="quotes">
        <Quotes ref="quotes" 
                class="mt-5" 
                :model-id="issue.id"
                model-type="issue"
                @delete="onQuoteDelete" />
      </v-tab-item>
      <v-tab-item key="notes">
        <Notes ref="notes" 
               class="mt-5" 
               model-type="issue"
               :model-id="issue.id"
               @delete="onNoteDelete" />
      </v-tab-item>
      <v-tab-item key="topics">
        <Topics :model-id="issue.id" model-type="issue" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import Notes from '../note/Notes'
  import Quotes from '../quote/Quotes'
  import Topics from '../topic/Topics'

  export default {
    name: 'IssueTabs',

    components: {
      Notes,
      Quotes,
      Topics
    },

    props: {
      issue: {
        type: Object
      }
    },

    data: () => ({
      tab: 'quotes'
    }),

    mounted(){},

    methods: {
      fetchNotes()
      {
        this.$refs.notes.fetchNotes()
      },
      fetchQuotes()
      {
        this.$refs.quotes.fetchQuotes()
      },
      onNoteDelete()
      {
        this.issue.notes_count--
      },
      onQuoteDelete()
      {
        this.issue.quotes_count--
      }
    }

  }
</script>
