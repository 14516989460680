<template>

  <v-row>
    
    <Loading :status="loading"/>

    <v-col cols="12">
      <v-text-field solo
        label="FILTER RESULTS"
        v-model="filter"
        append-icon="mdi-filter"
        @click:append="lookup"
        @keyup.enter="lookup"
      ></v-text-field>
    </v-col>

    <v-col cols="12" v-if="hasNoIssues">
      <Message>No issues matching current search.</Message>
    </v-col>
    
    <v-col cols="6" md="2"
           v-for="(issue) in issues"
           :key="issue.hash">
      <IssueCard :issue="issue"
                 @click="onClick"/>
    </v-col>

  </v-row>

</template>

<script>

  import _ from 'lodash'

  import Api from '../../../api'

  import IssueCard from '../Card'
  import Loading from '../../Loading'
  import Message from '../../Message'

  export default {
    name: 'ListIssueModal',

    components: {
      IssueCard,
      Loading,
      Message
    },

    props: {
      magazineId: {
        type: Number
      },
      search: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      filter: '',
      issues: [],
      loading: false
    }),

    computed: {
      hasNoIssues()
      {
        return _.isEmpty(this.issues)
      },
      searchString()
      {
        var s = _.chain([this.search, this.filter])
                 .filter()
                 .join(' ')
                 .value()

        return s
      }
    },

    mounted(){
      this.lookup()
    },

    methods: {
      lookup()
      {
        var ws = null

        if( this.magazineId )
          ws = Api.magazines.lookup(this.magazineId, this.filter)

        else
          ws = Api.issues.lookup(this.searchString)

        if( !ws )
          return

        this.loading = true

        ws.then((res)=>{
                      
          this.issues = res.data
            
          this.loading = false

        })
        .catch(()=>{

          this.issues = []
            
          this.loading = false

        })
        
      },
      onClick(issue)
      {
        this.$emit('selected', issue)
      }
    },

    watch: {
      search()
      {
        this.lookup()
      }
    },

  }
</script>
