<template>
  <div>
    <v-row align="start" v-if="issueIsLoaded">
        <v-col cols="12" md="4" 
               v-if="issue.cover_url"
               class="hidden-print-only">
          <v-img :src="issue.cover_url" 
                 class="book-cover "></v-img>
        </v-col>
        <v-col cols="12" :md="issue.cover_url ? 8 : 12">

          <v-chip
            class="ma-2"
            color="primary"
            label
            v-for="(author,i) in issue.authors" 
            :key="`author-${i}`"
            @click="goToAuthor(author)"
          >
            <v-icon left>mdi-account-circle-outline</v-icon>
            {{ author.fullname }}
          </v-chip>
          
          <h1 class="my-5">
            <FavouriteButton :status="isFav"
                             model-type="issue" 
                             :model-id="issue.id" 
                             @toggled="onFavToggle"
                             v-if="issue.id"/>
            {{ issue.title }}
          </h1>

          <div v-if="issue.description"
               v-html="issue.description"></div>

          <v-simple-table class="my-4">
            <template v-slot:default>
              <tbody>
                <tr v-if="hasMagazine">
                  <th>Magazine</th>
                  <td @click="goToMagazine">{{ magazine.title }}</td>
                </tr>
                <tr v-if="issue.ean">
                  <th>EAN</th>
                  <td>{{ issue.ean }}</td>
                </tr>
                <tr v-if="issue.number">
                  <th>Number</th>
                  <td>{{ issue.number }}</td>
                </tr>
                <tr v-if="issue.published_at">
                  <th>Publishing date</th>
                  <td>{{ formatDate(issue.published_at) }}</td>
                </tr>
                <tr v-if="issue.position">
                  <th>Position</th>
                  <td>{{ issue.position }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <slot></slot>

        </v-col>
      </v-row>
    <v-row v-else>
      <v-col cols="12" md="4">
        <v-skeleton-loader type="card-avatar"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="8">
        <v-skeleton-loader type="article,article"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<style type="text/css">
  @media (max-width: 767px) {
    .book-cover .v-responsive__sizer {
      padding-bottom: 30vh !important;
    }
    .book-cover .v-image__image {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
</style>

<script>
  
  import _ from 'lodash'
  import Api from '../../api'
  import Utils from '../../utils'

  import FavouriteButton from '../FavouriteButton'

  export default {
    name: 'IssueRow',

    mixins: [
      Utils
    ],

    components: {
      FavouriteButton
    },

    props: {
      issue: {
        type: Object,
        default: ()=>{
          return {
            authors: []
          }
        }
      },
      height: {
        type: Number,
        default: 350
      }
    },

    data: () => ({
      isFav: false,
      magazine: {}
    }),

    mounted() {
      this.fetchMagazine()
      this.loadFavourites()
    },

    computed: {
      hasMagazine()
      {
        return !_.isEmpty(this.magazine)
      },
      issueIsLoaded()
      {
        return this.issue.id
      }
    },

    methods: {
      fetchMagazine()
      {
        if( !this.issue.magazine_id )
          return

        Api.magazines.one(this.issue.magazine_id)
                     .then((res)=>{
                      this.magazine = res.data.data
                     })
      },
      goToAuthor(author)
      {
        this.$router.push({ name: 'author', params: { authorId: author.id } })
      },
      goToMagazine()
      {
        if( !this.magazine.id )
          return

        this.$router.push({ name: 'magazine', params: { magazineId: this.magazine.id } })
      },
      loadFavourites()
      {
        Api.favourites.list()
           .then((res)=>{
            var issue = _.chain(res.data.issues)
                        .filter({ id:this.issue.id })
                        .value()
              this.isFav = !_.isEmpty(issue)
           })
      },
      onFavToggle()
      {
        this.loadFavourites()
      },
    },

    watch: {
      issue()
      {
        this.fetchMagazine()
      }
    }

  }
</script>
