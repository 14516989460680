<template>

  <v-form>
    <v-container>
      <v-row>

        <v-col cols="12">
          <v-select
            :items="options"
            v-model="magazineId"
            label="Magazine"
          ></v-select>
        </v-col>

      </v-row>
    </v-container>
  </v-form>

</template>

<script>
  
  import _ from 'lodash'

  import Api from '../../../api'

  export default {
    name: 'MagazineIssueModal',

    components: {},

    props: {
      
    },

    data: () => ({
      magazines: [],
      magazineId: null,
      options: []
    }),

    mounted(){
      this.loadMagazines()
    },

    methods: {
      loadMagazines()
      {
        Api.magazines.list().then((res)=>{
          var magazines = res.data.data
          this.magazines = magazines
          this.options = _.chain(magazines)
                            .sortBy('title')
                            .map((magazine)=>{
                              return {
                                text: magazine.title,
                                value: magazine.id
                              }
                            })
                            .value()
        })
      }
    },

    watch: {
      magazineId(newValue)
      {
        if(newValue)
          this.$emit('selected', _.chain(this.magazines)
                                  .filter({id:newValue})
                                  .first()
                                  .value())
      }
    },

  }
</script>
