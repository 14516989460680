<template>

  <v-dialog scrollable 
            v-model="dialog" 
            :fullscreen="$mq=='sm'">

    <template v-slot:activator="{ on }">
      <v-btn :color="color"
        :icon="onlyIcon"
        v-on="on" 
        :class="buttonClass"
        :fab="fab" 
        :dark="dark"
      >
        <v-icon :class=" label ? 'mr-2':'' ">{{ icon }}</v-icon>
        {{ label }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="headline red darken-2 white--text"
        primary-title
      >
        <span>Book genres</span>
      </v-card-title>

      <v-card-text>

        <Genres list 
                :book="book" 
                title=""
                @toggle="onToggle"/>
        
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="grey">
        <v-btn text dark @click="dialog = false">
          <v-icon class="mr-1">mdi-close</v-icon>
          Close
        </v-btn>
      </v-card-actions>
    </v-card>

    <Loading :status="overlay"/>

  </v-dialog>

</template>

<script>

  import Genres from './Genres'
  import Loading from '../Loading'

  export default {
    name: 'GenreModal',

    components: {
      Genres,
      Loading
    },

    props: {
      book: {
        type: Object
      },
      buttonClass: {
        type: String
      },
      color: {
        type: String
      },
      dark: {
        type: Boolean,
        default: false
      },
      fab: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: ()=> 'mdi-pencil-circle'
      },
      label: {
        type: String,
        default: ()=> ''
      },
      onlyIcon: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      dialog: false,
      overlay: false
    }),

    mounted(){},

    methods: {

      onToggle(genre, status)
      {
        this.$emit('toggle', genre, status)
      },
      
      show()
      {
        this.dialog = true
      }
    },

    watch: {},

  }
</script>
