<template>

  <v-form>
    <v-container>
      <v-row>

        <v-col cols="12">
          <v-img contain
            :src="theIssue.cover_url"
            height="200px"
            v-if="theIssue.cover_url"
          ></v-img>
        </v-col>

        <v-col cols="12">
          <v-text-field
            label="Title"
            v-model="theIssue.title"
            v-on:keyup.enter="save"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            label="Description"
            v-model="theIssue.description"
            v-on:keyup.enter="save"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            label="Number"
            v-model="theIssue.number"
            v-on:keyup.enter="save"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-dialog
            ref="datepicker"
            v-model="datepicker"
            :return-value.sync="theIssue.published_at"
            persistent
            width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="theIssue.published_at"
                label="Published at"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="theIssue.published_at"
              type="month"
              color="red"
              scrollable>
              <v-btn
                text
                color="grey"
                @click="datepicker = false">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success"
                @click="$refs.datepicker.save(theIssue.published_at)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12">
          <v-text-field
            label="EAN"
            v-model="theIssue.ean"
            v-on:keyup.enter="save"
          ></v-text-field>
        </v-col>

      </v-row>
    </v-container>
  </v-form>

</template>

<script>

  export default {
    name: 'FormIssueModal',

    components: {},

    props: {
      issue: {
        type: Object
      }
    },

    data: () => ({
      datepicker: false,
      theIssue: {}
    }),

    mounted(){
      if(this.issue)
        this.theIssue = this.issue
    },

    methods: {
      save()
      {
        this.$emit('save')
      }      
    },

    watch: {
      issue(issue)
      {
        if(issue)
          this.theIssue = issue
      },
      theIssue(issue)
      {
        this.$emit('update:issue',issue)
      }
    },

    computed: {
      
    }
  }
</script>
