<template>
  <div @click="$emit('click')">
    <div>
      <span class="text-h5">
        {{ title }}
      </span>
    </div>

    <template v-if="!filteredGenres.length">
      <slot name="empty"></slot>
    </template>

    <template v-else>
    
      <template v-if="asChips">
        <v-chip
          class="ma-2"
          :color="isActive(genre)"
          v-for="genre in filteredGenres" 
          :key="genre.id"
          @click="toggle(genre)"
        >
          <v-progress-circular
                    :size="20"
                    :width="3"
                    indeterminate
                    v-if="genre.id===isLoading"
                  ></v-progress-circular>
          <span v-else>{{ genre.name }}</span>
        </v-chip>
      </template>

      <template v-else>
        <v-list class="primary--text">
          <div v-for="(genres,letter) in groupedGenres" 
             :key="letter">

            <v-subheader>
              {{ letter }}
            </v-subheader>
          
            <v-list-item 
              v-for="genre in genres" 
              :key="genre.id"
              @click="toggle(genre)"
              :class="isActive(genre)">

              <v-list-item-content>
                <v-list-item-title>
                  <v-progress-circular
                    :size="20"
                    :width="3"
                    indeterminate
                    v-if="genre.id===isLoading"
                  ></v-progress-circular>
                  {{ genre.name }}
                </v-list-item-title>
              </v-list-item-content>

            </v-list-item>

          </div>
        </v-list>
      </template>

    </template>

  </div>
</template>

<style type="text/css">
  
</style>

<script>

  import _ from 'lodash'
  import Api from '../../api'

  export default {
    name: 'Genres',

    components: {},

    props: {
      book: {
        type: Object,
        default: ()=>{
          return {
            genres: []
          }
        }
      },
      list: {
        type: Boolean,
        default: false
      },
      onlySelected: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: 'Genres'
      }
    },

    data: () => ({
      genres: [],
      isLoading: null
    }),

    computed: {
      asChips()
      {
        return !this.list
      },
      asList()
      {
        return this.list
      },
      filteredGenres()
      {
        var genres = this.onlySelected ? this.book.genres : this.genres

        return _.orderBy(genres,'name')
      },
      groupedGenres()
      {
        return _.groupBy(this.filteredGenres, (genre)=> {
          return genre.name.toUpperCase().substring(0,1)
        })
      }
    },

    mounted(){
      this.loadGenres()
    },

    methods: {
      isActive(genre)
      {
        if(this.book.genres && !this.book.genres.length)
          return ''

        let isActive = _.find(this.book.genres, {id:genre.id}),
            label = isActive ? 'primary' : '',
            labelList = isActive ? 'v-item--active v-list-item--active' : ''

        return this.list ? labelList : label
      },
      loadGenres()
      {
        Api.genres.list({ per_page:-1 }).then((res)=>{
          // res.data.links
          this.genres = res.data.data
        })
      },
      toggle(genre)
      {
        if( !this.book.id )
          return;

        if( this.readonly )
          return;

        let bookId = this.book.id

        this.isLoading = genre.id

        Api.genres.toggle(genre.id,bookId).then((res)=>{
          this.$emit('toggle', genre, res.data.new_status)
          this.isLoading = null
        })
      }
    }

  }
</script>
