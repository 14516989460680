<template>

  <v-dialog
      v-model="dialog"
      :width="isStep('issues') ? '' : '50vw'"
      :fullscreen="$mq=='sm'">

    <v-card>
      <v-card-title
        class="headline red darken-2 white--text"
        primary-title
      >
        <span v-if="issueId">Edit Issue</span>
        <span v-else>Add an Issue</span>
      </v-card-title>

      <v-card-text>

        <MagazineIssueModal @selected="onMagazineSelected"
                            v-if="isStep('magazine')">
        </MagazineIssueModal>

        <ListIssueModal v-else-if="isStep('issues')"
                        :magazine-id="magazine.id"
                        @selected="onIssueSelected">
        </ListIssueModal>

        <FormIssueModal :issue.sync="theIssue"
                        @save="save"
                        v-else>
        </FormIssueModal>
        
      </v-card-text>

      <v-card-actions class="grey">
        <v-btn text dark @click="dialog = false">
          <v-icon class="mr-1">mdi-close</v-icon>
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" 
               @click="save"
               v-if="isStep('form')">
          <v-icon class="mr-1">mdi-check</v-icon>
          Save
        </v-btn>
        <v-btn dark
               @click="goToStep('form')"
               v-else-if="isStep('issues')">
          Insert data
          <v-icon class="ml-1">mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>

    </v-card>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-dialog>

</template>

<script>

  import _ from 'lodash'

  import Api from '../../api'

  import FormIssueModal from './modal/Form'
  import ListIssueModal from './modal/List'
  import MagazineIssueModal from './modal/Magazine'

  export default {
    name: 'IssueModal',

    components: {
      FormIssueModal,
      ListIssueModal,
      MagazineIssueModal
    },

    props: {
      magazineId: {
        type: Number
      },
      issue: {
        type: Object,
        default: ()=> { return {} }
      },
      mode: {
        type: String
      },
    },

    data: () => ({
      dialog: false,
      magazine: {},
      overlay: false,
      step: 'magazine', // magazine, issues, form
      theIssue: {},
    }),

    computed: {
      issueId()
      {
        return this.issue.id
      }
    },

    mounted(){
      if(this.mode)
        this.goToStep(this.mode)

      if(this.issue)
        this.theIssue = this.issue
    },

    methods: {
      /**
       * Go to a specific modal step.
       */
      goToStep(step)
      {
        this.step = step
      },
      /**
       * Check current modal step.
       */
      isStep(step)
      {
        return this.step === step
      },
      /**
       * When an issue is selected in lookup.
       */
      onIssueSelected(issue)
      {
        this.overlay = true

        Api.issues.lookup(issue.hash)
                  .then((res)=>{

                    this.theIssue = res.data.shift()
                    
                    this.overlay = false

                  })
      },
      /**
       * When magazine is selected in dropdown.
       */
      onMagazineSelected(magazine)
      {
        this.magazine = magazine
      },
      save()
      {
        this.overlay = true

        var magazine_id = this.theIssue.magazine_id || this.magazine.id

        var issue = _.chain(this.theIssue)
                     .clone()
                     .extend({ magazine_id })
                     .value()

        var ws = this.issue.id ? 
                 Api.issues.update(this.issue.id, issue) : 
                 Api.issues.create(issue)
        
        ws.then((res)=>{
            
            console.log(res.data.data)

            this.overlay = false
            this.dialog = false
            this.$emit('save', res.data.data)

          })
          .catch((e)=>{
            this.overlay = false
            this.$store.commit('growl', {
              color: 'error',
              text: e.response.data.message
            })
          })

      },
      reset()
      {
        this.theIssue = {}
      },
      show()
      {
        this.dialog = true
      }
    },

    watch: {
      dialog(isOpening)
      {
        if( !isOpening )
          this.reset()
      },
      issue(issue)
      {
        if(issue)
          this.theIssue = issue
      },
      magazineId(magazineId)
      {
        if(magazineId)
          Api.magazines.one(magazineId)
                       .then((res)=>{
                        this.magazine = res.data.data
                       })
      },
      magazine(magazine)
      {
        if(magazine && magazine.id)
          this.goToStep('issues')
      },
      mode(step)
      {
        this.goToStep(step)
      },
      theIssue(issue)
      {
        if(_.isEmpty(issue))
          this.goToStep('magazine')

        else
          this.goToStep('form')
      }
    },

  }
</script>
