<template>
  <v-card
    class="mx-auto"
    max-width="344" 
    @click="onClick"
    elevation="24"
    style="overflow:hidden"
  >
    
    <v-img
      :src="item.cover_url"
      :height=" height + 'px'" 
      v-if="item.cover_url"
    ></v-img>

    <div v-else>

      <v-card-title>
        {{ item.title }}
      </v-card-title>

      <v-card-subtitle>
        <!-- ... -->
      </v-card-subtitle>

    </div>

  </v-card>
</template>

<script>
  export default {
    name: 'IssueCard',

    components: {},

    props: {
      issue: {
        type: Object
      },
      height: {
        type: Number,
        default: 350
      }
    },

    data: () => ({
      item: {}
    }),

    mounted(){
      if(this.issue)
        this.item = this.issue
    },

    computed: {

    },

    methods: {
      onClick()
      {
        this.$emit('click', this.issue)
      }
    }

  }
</script>
